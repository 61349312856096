<template>
  <div class="min-h-screen bg-white">
    <Disclosure
      as="nav"
      class="bg-white border-b border-gray-200"
      v-slot="{ open }"
    >
      <div class="mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <img
                class="block lg:hidden h-10 w-auto"
                src="@/assets/logo.png"
                alt="Workflow"
              />
              <img
                class="hidden lg:block h-10 w-auto"
                src="@/assets/logo.png"
                alt="Workflow"
              />
            </div>
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-10">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :exact="item.exact"
                :to="{ name: item.href }"
                exact-active-class="border-brand-pink text-gray-900"
                class="
                  border-transparent
                  text-secondary-dark-dark
                  hover:border-secondary-light hover:text-gray-700
                  inline-flex
                  items-center
                  px-1
                  pt-1
                  border-b-2
                  font-medium
                  text-md
                "
                :aria-current="item.current ? 'page' : undefined"
              >
                <component :is="item.icon" class="w-6 h-6" />
                <span class="ml-1">{{ item.name }}</span>
              </router-link>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <button
              class="
                bg-white
                p-1
                rounded-full
                text-gray-400
                hover:text-secondary-dark
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-primary-dark
              "
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>

            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton
                  class="
                    max-w-xs
                    bg-white
                    flex
                    items-center
                    text-sm
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-primary-dark
                  "
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="h-8 w-8 rounded-full"
                    src="@/assets/user.png"
                    alt=""
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                  style="z-index: 1000"
                >
                  <MenuItem
                    v-for="item in userNavigation"
                    :key="item.name"
                    v-slot="{ active }"
                    class="flex items-center"
                  >
                    <a
                      v-if="item.href"
                      :href="item.href"
                      class="
                        block
                        px-4
                        py-2
                        text-sm text-gray-700
                        hover:bg-gray-100
                      "
                    >
                      <component :is="item.icon" class="w-4 h-4" />
                      <span class="ml-3">{{ item.name }}</span>
                    </a>
                    <router-link
                      v-else
                      :exact="item.exact"
                      :to="{ name: item.route }"
                      active-class="bg-gray-100"
                      excat-active-class="bg-gray-100"
                      :class="{ 'bg-gray-100': active }"
                      class="
                        block
                        px-4
                        py-2
                        text-sm text-gray-700
                        hover:bg-gray-100
                      "
                    >
                      <component :is="item.icon" class="w-4 h-4" />
                      <span class="ml-3">{{ item.name }}</span>
                    </router-link>
                  </MenuItem>

                  <MenuItem class="flex items-center">
                    <a
                      href=""
                      class="
                        block
                        px-4
                        py-2
                        text-sm text-red-700
                        hover:bg-red-100
                      "
                      @click.prevent="logout"
                    >
                      <LogoutIcon class="w-4 h-4" />
                      <span class="ml-3">Sign out</span>
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="
                bg-white
                inline-flex
                items-center
                justify-center
                p-2
                rounded-md
                text-gray-400
                hover:text-secondary-dark hover:bg-gray-100
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-primary-dark
              "
            >
              <span class="sr-only">Open main menu</span>
              <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="pt-2 pb-3 space-y-1">
          <router-link
            v-for="item in navigation"
            :key="item.name"
            :exact="item.exact"
            :to="{ name: item.href }"
            exact-active-class="border-primary-dark text-gray-900"
            class="
              border-transparent
              text-gray-600
              hover:bg-secondary hover:border-secondary-light
              pl-3
              pr-4
              py-2
              border-l-4
              text-base
              font-medium
              flex
              items-center
            "
          >
            <component :is="item.icon" class="w-4 h-4" />
            <span class="ml-3">
              {{ item.name }}
            </span>
          </router-link>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-200">
          <div class="flex items-center px-4">
            <div class="flex-shrink-0">
              <img
                class="h-10 w-10 rounded-full"
                src="@/assets/user.png"
                alt=""
              />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-800">
                {{ user.name }}
              </div>
              <div class="text-sm font-medium text-secondary-dark">
                {{ user.email }}
              </div>
            </div>
            <button
              class="
                ml-auto
                bg-white
                flex-shrink-0
                p-1
                rounded-full
                text-gray-400
                hover:text-secondary-dark
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-primary-dark
              "
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-3 space-y-1" style="z-index: 1000">
            <a
              v-for="item in userNavigation"
              :key="item.name"
              :href="item.href"
              class="
                block
                px-4
                py-2
                text-base
                font-medium
                text-secondary-dark
                hover:text-gray-800 hover:bg-gray-100
              "
            >
              {{ item.name }}
            </a>

            <a
              href=""
              class="
                block
                px-4
                py-2
                text-base
                font-medium
                text-secondary-dark
                hover:text-red-800 hover:bg-red-100
              "
              @click.prevent="logout"
            >
              Sign out
            </a>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>

    <main>
      <div class="mx-auto">
        <!-- Replace with your content -->
        <div class="px-4 sm:px-0">
          <router-view />
        </div>
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { createNamespacedHelpers } from "vuex";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { BellIcon, MenuIcon, XIcon, LogoutIcon } from "@heroicons/vue/outline";
import { ChartPieIcon, AdjustmentsIcon, CogIcon } from "@heroicons/vue/outline";
import { DatabaseIcon, UserCircleIcon } from "@heroicons/vue/outline";
import { profileUri, logoutUri } from "@/config/env";

const { mapGetters } = createNamespacedHelpers("auth");

const navigation = [
  { name: "Dashboard", icon: ChartPieIcon, href: "dashboard", exact: true },
  { name: "Connections", icon: DatabaseIcon, href: "connections.index" },
  { name: "Workflows", icon: AdjustmentsIcon, href: "workflows.index" },
  { name: "Settings", icon: CogIcon, href: "settings.index" },
];

const userNavigation = [
  { name: "Your Profile", href: profileUri, icon: UserCircleIcon },
  { name: "Settings", route: "settings.index", icon: CogIcon },
];

export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon,
    MenuIcon,
    XIcon,
    LogoutIcon,
  },

  computed: {
    ...mapGetters(["user"]),
  },

  setup() {
    return {
      navigation,
      userNavigation,
    };
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      window.location.replace(logoutUri);
    },
  },
});
</script>
